@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#bookMeeting {
  position: fixed; 
  top: 42px; 
  z-index: 1300;
  inset: 0px;
  /* background-color: rgba(0, 0, 0, 0.8);  */
  visibility: hidden;
}
#bookMeeting .MuiBackdrop-root {
  top: 42px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: -1;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
}
.meeting-popup{
  margin: 10px auto;
  width: 800px;
  max-height: 678px;
  min-height: 516px;
  padding-top: 20px;
}

/* top UI atyles */

label {  cursor: pointer; padding: 2px 4px 2px 4px; }
label span{ padding: 0px 4px 0px 8px; position: relative; vertical-align: middle; bottom: 2px; }

#topTitle {
    display: flex;
    position: absolute;
    width: 100%;
    padding: 0 9px 0 16px;
    font-size: 1rem;
    color: #FFFFFF;
    height: 47px;
    min-height: 47px;
    background-color: #292929;
    border-bottom: 1px solid #5c5c5c;
    box-shadow: 0 0px 5px 0 #2b2b2b;
    /* opacity: 0.85; */
    text-align: left; 
    vertical-align: middle; 
    justify-content: flex-start; 
    align-items: center; 
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
#topTitle span.title{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

#topUIToolbarExpanded{
    /* display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: auto;
}

#topUIToolbarExpanded .MuiIconButton-root{
    border-radius: 0;
    width: 30px;
    height: 30px;
    padding: 0;
    color: #5a5a5a;
    background-color: transparent;
}
#topUIToolbarExpanded .MuiIconButton-root svg{
    width: 1em;
    height: 1em;
    fill: #5a5a5a;
    stroke: #5a5a5a;
}

#topUIToolbarExpanded .MuiIconButton-root:hover, #topUIToolbarExpanded .MuiIconButton-root svg:hover{
    color: #E60000;
    background-color: transparent;
    fill: #E60000;
    stroke: #E60000;
}      
#topUIToolbarExpanded .MuiIconButton-root svg.active {
    color: #E60000;
    fill: #E60000;
    stroke: #E60000;
}
.svg_diagram{
    margin-top: 45px;
}
.checkboxItems {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
}
.MuiSlider-rail, .MuiSlider-track{
    background-color: #fff !important;
}
.floatItem {
    float: left;
    max-width: 50% !important;
    min-width: 47%;
}
.listIconItem .MuiListItemIcon-root{
    min-width: auto !important;
}

.listIconItem .MuiListItemIcon-root:hover{
    color: #E60000;
}

.listIconItem.heading{
    padding-bottom: 0;
    cursor: text;
}
.listIconItem.heading:hover{
    background: none;
}
.listIconItem.heading .MuiListItemText-root{
    margin-bottom: 0;
}
.checkbox{
    min-width: auto !important;
}
.checkbox .MuiIconButton-root {
    padding: 0 5px 0 0;
}
.checkbox svg.MuiSvgIcon-root{
    width: 1rem;
    height: 1rem;
    font-size: 1rem;
}
.show_counters{
    display: flex;
    align-items: center;    
    justify-content: center;
    flex-basis: 10px !important;
}
.show_counters.custom-width{
    flex-basis: 0px !important;
}
.show_label{
    margin-left: 10px;
    margin-right: 10px;
}
#topUIToolbarExpanded .uspto_logo{
    width: 75px;
}
.empty:hover{
    background: none !important;
}
.uspto_logo_container{
    display: flex;
    align-items: center;
}

#bottomUIToolbarContainer {
    width: 100%;
    height: 32px;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    min-width: 100vw;
}



#bottomUIToolbarExpanded {
    display: flex;
    /* justify-content: space-between; */
    width: 100%;
    height: 32px;
    grid-template-columns: repeat(18, -webkit-max-content) repeat(3, 1fr) repeat(3, -webkit-max-content);
    grid-template-columns: repeat(18, max-content) repeat(3, 1fr) repeat(3, max-content);
    grid-row-gap: 0px;
    background-color: #424242;
    box-shadow: 0 0px 5px 0 #2b2b2b;
    font-size: 12px;
    color: #FFFFFF;
    opacity: 0.85;
    
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

}
#bottomUIToolbarClosed:hover {
  background-color: rgba(18 ,18 ,18, 1);
  -webkit-transform: translateX(-15px);
          transform: translateX(-15px);
}
#bottomUIToolbarClosed {
    display: grid;
    grid-template-columns: repeat(2, -webkit-max-content);
    grid-template-columns: repeat(2, max-content);
    grid-row-gap: 0px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 32px;
    background-color: #111111;
    background-color: rgba(18 ,18 ,18, 0.5);
    font-size: 14px;
    color: #FFFFFF;
    opacity: 0.85;
    border-radius: 0 50px 50px 0;
    -webkit-transform: translateX(-30px);
            transform: translateX(-30px);
    transition: .2s;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */   
    
}

.toolbarUIElement { 
    display: flex; 
    cursor: pointer; 
    text-align: center; 
    vertical-align:  middle; 
    line-height: 32px; 
    justify-content: center; 
    align-items: center; 
}
.toolbarUIElement svg {    position: unset !important;  }
.toolbarUIGap { 
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.toolbarUIPopupOn { 
    display: block; 
}

.toolbarUIPopupOff{ 
    display: none; 
}

.toolbarUIQuantative {
    text-align: center; 
    vertical-align: middle; 
    line-height: 34px;
    justify-content: flex-start; 
    align-items: center;
    min-width: 3.5rem;
}

.toolbarUIList { 
    text-align: left; 
    vertical-align: middle; 
    line-height: 1.5rem; 
    justify-content: flex-start; 
    align-items: center; 
}

.toolbarUIPopup {
    display: none;
    position: absolute;
    padding: 0.5rem;
    color: #FFFFFF;
    font-size: 12px;
    line-height: 1.5;
    background-color: #111111;
    opacity: 0.85;
    text-align: left; 
    justify-content: flex-start; 
    align-items: flex-start;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
 
}
#patentrackDiagramDiv {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

#patentrackDiagramDiv svg { 
    flex: 1 1;    
    overflow: visible;    
    overflow: initial;
}

.diagram-container {
}

#patentrackDiagramDiv .toolbarUIPopup ui,#patentrackDiagramDiv li {  
    list-style-type: none;  
    text-indent: -2.4rem;
}
.wrapText tspan:nth-child(3){ 
    display: none;
}
div.tooltip_title {
    position: absolute;			
    text-align: left;			
    /* margin: 0 auto; */
    text-align: left;
    width: auto;
    max-width: 500px;
    border: 0 !important;	
    border-radius: 0 !important;	
    background: #121212;
    pointer-events: none;
    font-weight: inherit; 
    font-size: 12.25px;
    white-space: normal;
}
.tooltipContainer {
    display: flex;
    justify-content: center;
}
.tooltipContainer  .svg-inline--fa.fa-w-14{
    /* width: 1rem;
    height: 1rem; */
}
.tooltip {
    font-size: 1rem;
    border-radius: 0;
}
.uspto_logo{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
}
.uspto_logo img{
    width: 40px;
}
/*
#toobarUIGap0 { grid-area: 1 / 1 / 2 / 2; text-align: center; justify-content: center; align-items: center; }
#expandOnOff { grid-area: 1 / 2 / 2 / 3; text-align: center; justify-content: center; align-items: center; }
#toobarUIGap1 { grid-area: 1 / 3 / 2 / 4; text-align: center; justify-content: center; align-items: center; }
#fastBackward { grid-area: 1 / 4 / 2 / 5; text-align: center; justify-content: center; align-items: center; }
#fastForward { grid-area: 1 / 5 / 2 / 6; text-align: center; justify-content: center; align-items: center; }
#toobarUIGap2 { grid-area: 1 / 6 / 2 / 7; text-align: center; justify-content: center; align-items: center; }
#prevAssignment { grid-area: 1 / 7 / 2 / 8; text-align: center; justify-content: center; align-items: center; }
#assignmentQuantative { grid-area: 1 / 8 / 2 / 9; text-align: center; justify-content: center; align-items: center; }
#nextAssignment { grid-area: 1 / 9 / 2 / 10; text-align: center; justify-content: center; align-items: center; }
#toobarUIGap3 { grid-area: 1 / 10 / 2 / 11; text-align: center; justify-content: center; align-items: center; }
#prevAssignee { grid-area: 1 / 11 / 2 / 12; text-align: center; justify-content: center; align-items: center; }
#assigneeQuantative { grid-area: 1 / 12 / 2 / 13; text-align: center; justify-content: center; align-items: center; }
#nextAssignee { grid-area: 1 / 13 / 2 / 14; text-align: center; justify-content: center; align-items: center; }
#toobarUIGap4 { grid-area: 1 / 14 / 2 / 15; text-align: center; justify-content: center; align-items: center; }
#PatentrackMultiCheckbox { grid-area: 1 / 15 / 2 / 16; text-align: center; justify-content: center; align-items: center; }
#PatentrackLegend { grid-area: 1 / 16 / 2 / 17; text-align: center; justify-content: center; align-items: center; }
#toobarUIGap5 { grid-area: 1 / 17 / 2 / 18; text-align: center; justify-content: center; align-items: center; }
#horizontalExtenderA { grid-area: 1 / 18 / 2 / 19; text-align: center; justify-content: center; align-items: center; }
#horizontalExtenderB { grid-area: 1 / 19 / 2 / 20; text-align: center; justify-content: center; align-items: center; }
#zoomDiagram { grid-area: 1 / 20 / 2 / 21; text-align: center; justify-content: center; align-items: center; }
#toobarUIGap6 { grid-area: 1 / 21 / 2 / 22; text-align: center; justify-content: center; align-items: center; }
#shareDiagram { grid-area: 1 / 22 / 2 / 23; text-align: center; justify-content: center; align-items: center; }
*/




.ql-container {
    background: #424242;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  } 
  .ql-toolbar {
    background: rgb(48, 48, 48);
    border-top: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .ql-snow.ql-toolbar button, .ql-snow .ql-toolbar button{
    /* width: 32px;
    height: 32px; */
    width: 'auto';
    height: 'auto';
  }
  .ql-snow .ql-picker, .ql-toolbar, .ql-container, .ql-picker-label, .ql-picker-label svg, .ql-snow.ql-toolbar button, .ql-snow .ql-toolbar button, .ql-snow.ql-toolbar button svg, .ql-snow .ql-toolbar button svg, .ql-snow .ql-stroke, .ql-picker-label{
    color: #fff;
    stroke: #fff;
  }
  
  .ql-snow .ql-stroke, .ql-snow button.ql-bold , .ql-snow button.ql-italic , .ql-snow button.ql-atButton , .ql-snow button.ql-atButton svg, .ql-snow button.ql-attachButton , .ql-snow button.ql-attachButton svg, .ql-snow button.ql-saveButton , .ql-snow button.ql-saveButton svg {
    stroke: #545454;
    color: #545454;
    pointer-events: none;
  }
  
  .focus .ql-snow .ql-stroke, .focus .ql-snow button.ql-atButton svg, .focus .ql-snow .ql-attachButton svg, .focus .ql-snow .ql-saveButton svg {
    color: #fff;
    pointer-events: all;
  }
  
  .focus .ql-snow .ql-stroke{  
    stroke: #fff;
  }
  
  .ql-snow .ql-transparent{ 
    opacity: 1;
  }
  
  .ql-snow.ql-toolbar button:hover, .ql-snow .ql-toolbar button:hover, .ql-snow.ql-toolbar button:focus, .ql-snow .ql-toolbar button:focus, .ql-snow.ql-toolbar button.ql-active, .ql-snow .ql-toolbar button.ql-active, .ql-snow.ql-toolbar .ql-picker-label:hover, .ql-snow .ql-toolbar .ql-picker-label:hover, .ql-snow.ql-toolbar .ql-picker-label.ql-active, .ql-snow .ql-toolbar .ql-picker-label.ql-active {
    color: #fff;
  }
  .ql-snow .ql-picker.ql-expanded .ql-picker-options{
    bottom: 100%;
    top: unset
  }
  .ql-snow .ql-picker-options .ql-picker-item, .ql-snow.ql-toolbar .ql-picker-item:hover,.ql-snow .ql-toolbar .ql-picker-item:hover, .ql-snow.ql-toolbar .ql-picker-item.ql-selected, .ql-snow .ql-toolbar .ql-picker-item.ql-selected{
    color: rgb(48, 48, 48)
  }
  .ql-container.ql-snow, .ql-toolbar.ql-snow{
    border: 1px solid #5c5c5c;
  }
  .ql-editor{
    padding: 11px 15px
  }
  .ql-toolbar.ql-snow{
    border-top: 0px;
    padding: 4px;
  }
  .ql-snow.ql-toolbar button svg{
    stroke: none;
  }
  
  #toolbar button:focus{
    outline: 0;
  }
   .focus .ql-snow.ql-toolbar button:hover, .focus .ql-snow.ql-toolbar button svg:focus, .focus .ql-snow.ql-toolbar button svg:hover, .focus .ql-snow.ql-toolbar button.ql-bold:hover svg.MuiSvgIcon-root .ql-stroke, .focus .ql-snow.ql-toolbar button.ql-bold:focus svg.MuiSvgIcon-root .ql-stroke, .focus .ql-snow.ql-toolbar button.ql-italic:hover svg.MuiSvgIcon-root .ql-stroke, .focus .ql-snow.ql-toolbar button.ql-italic:focus svg.MuiSvgIcon-root .ql-stroke{
    color: #e60000;
    stroke: #e60000;
  }
  .ql-snow.ql-toolbar button:hover, .ql-snow .ql-toolbar button:hover, .ql-snow .ql-toolbar button:focus, .ql-snow.ql-toolbar button.ql-active, .ql-snow .ql-toolbar button.ql-active, .ql-snow.ql-toolbar .ql-picker-label:hover, .ql-snow .ql-toolbar .ql-picker-label:hover, .ql-snow.ql-toolbar .ql-picker-label.ql-active, .ql-snow .ql-toolbar .ql-picker-label.ql-active, .ql-snow.ql-toolbar .ql-picker-item:hover, .ql-snow .ql-toolbar .ql-picker-item:hover, .ql-snow.ql-toolbar .ql-picker-item.ql-selected, .ql-snow .ql-toolbar .ql-picker-item.ql-selected, .ql-snow.ql-toolbar button:hover, .ql-snow .ql-toolbar button:hover,  .ql-snow .ql-toolbar button:focus, .ql-snow.ql-toolbar button.ql-active, .ql-snow .ql-toolbar button.ql-active, .ql-snow.ql-toolbar .ql-picker-label:hover, .ql-snow .ql-toolbar .ql-picker-label:hover, .ql-snow.ql-toolbar .ql-picker-label.ql-active, .ql-snow .ql-toolbar .ql-picker-label.ql-active {
    color: #e60000;
  }
  .ql-snow.ql-toolbar button:focus, .focus .ql-snow.ql-toolbar button:focus{
    color: inherit;
  }
  .ql-editor.ql-blank::before{
    color:#5a5a5a;
  }
  .ql-editor{
    max-height: calc(30vh - 80px)
  }
  
html{
  font-size: 14px;
}

html, body {
  height: 100%;
}

body {
  color: #fff;
  margin: 0;
  font-size: 0.875rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  scroll-behavior: smooth;
  background-color: #121212 !important;
  min-width: 800px;
  overflow: hidden;
}
.App {
  text-align: center;
  background: #121212 !important;
  height: 100vh;
  color: #fff
}

#root {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
}

.root {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  flex-wrap: nowrap !important;
  overflow-x: hidden;
  overflow-y: hidden;
  /* padding: 5px; */
  position: relative;
  height: 100%;
}
.dashboard {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #363636 ;
}
.dashboardWarapper {
  position: relative;
  flex-grow: 1;
  flex-direction: column;
  display: flex;
  height: auto;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 1;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.MuiGrid-container{
  margin: 0 5px;
  position: relative;
  height: 100%;
  width: auto !important;
}

.pan3 .Pane1{
  display: block
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.splitPane { 
   position: relative !important;
}
.splitPane .Resizer {
  opacity: 1;
  height: 100%;
  width: 3px;
  z-index: 1;
  box-sizing: border-box;
  cursor: col-resize;
  background-clip: padding-box;
  background:#000;
}
.splitPane .Resizer:hover{
  background: #f50057
}
.splitPane .Resizer.horizontal {
  height: 3px;
  width: 100%;
  cursor: row-resize;
}
.Pane {
  max-height: 100%
}
.Pane2{
  height: 100%;
  overflow: auto
}

.forceStrech {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.active, .MuiTableRow-root.Mui-selected .MuiTableCell-root{
  color: #f50057 !important
}

.pan3 .Pane1 {
  background: rgb(50, 54, 57);
}

.pan3 .Resizer.horizontal{
  display: none;
}

.filterToolbar {
  width: 3.5rem;
  border-Right: 0;
}
.flex {
  width: 3.5rem;
  position: absolute;
}
.bottom {
  bottom: 0
}

.illustration_comment_container{
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
}

.comment_container{
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  background: #424242;
}

.MuiPaper-root{
  background-color:#424242 !important;
  color:#fff !important;
}
.MuiPaper-root svg {
  color: #fff;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


@media (min-width: 80em) and (max-width: 95em) {
  html {
    font-size: 0.875rem;
  }
}
@media (min-width: 60em) and (max-width: 80em) {
  html {
    font-size: 0.875rem;
  }
}
@media (min-width: 40em) and (max-width: 60em) {
  html {
    font-size: 0.875rem;
  }
}

@media (max-width: 40em) {
  html {
    font-size: 0.875rem;
  }
}
