html{
  font-size: 14px;
}

html, body {
  height: 100%;
}

body {
  color: #fff;
  margin: 0;
  font-size: 0.875rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  scroll-behavior: smooth;
  background-color: #121212 !important;
  min-width: 800px;
  overflow: hidden;
}
.App {
  text-align: center;
  background: #121212 !important;
  height: 100vh;
  color: #fff
}

#root {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
}

.root {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  flex-wrap: nowrap !important;
  overflow-x: hidden;
  overflow-y: hidden;
  /* padding: 5px; */
  position: relative;
  height: 100%;
}
.dashboard {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #363636 ;
}
.dashboardWarapper {
  position: relative;
  flex-grow: 1;
  flex-direction: column;
  display: flex;
  height: auto;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 1;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.MuiGrid-container{
  margin: 0 5px;
  position: relative;
  height: 100%;
  width: auto !important;
}

.pan3 .Pane1{
  display: block
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.splitPane { 
   position: relative !important;
}
.splitPane .Resizer {
  opacity: 1;
  height: 100%;
  width: 3px;
  z-index: 1;
  box-sizing: border-box;
  cursor: col-resize;
  background-clip: padding-box;
  background:#000;
}
.splitPane .Resizer:hover{
  background: #f50057
}
.splitPane .Resizer.horizontal {
  height: 3px;
  width: 100%;
  cursor: row-resize;
}
.Pane {
  max-height: 100%
}
.Pane2{
  height: 100%;
  overflow: auto
}

.forceStrech {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.active, .MuiTableRow-root.Mui-selected .MuiTableCell-root{
  color: #f50057 !important
}

.pan3 .Pane1 {
  background: rgb(50, 54, 57);
}

.pan3 .Resizer.horizontal{
  display: none;
}

.filterToolbar {
  width: 3.5rem;
  border-Right: 0;
}
.flex {
  width: 3.5rem;
  position: absolute;
}
.bottom {
  bottom: 0
}

.illustration_comment_container{
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
}

.comment_container{
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  background: #424242;
}

.MuiPaper-root{
  background-color:#424242 !important;
  color:#fff !important;
}
.MuiPaper-root svg {
  color: #fff;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@media (80em <= width <= 95em) {
  html {
    font-size: 0.875rem;
  }
}
@media (60em <= width <= 80em) {
  html {
    font-size: 0.875rem;
  }
}
@media (40em <= width <= 60em) {
  html {
    font-size: 0.875rem;
  }
}

@media (max-width: 40em) {
  html {
    font-size: 0.875rem;
  }
}